// Colors
// $color-purple: #4a0b4d;
$color-purple: #18a7d6;
$color-white: #ffffff;
$color-black-50: rgba(0, 0, 0, 0.5);
.welcomeMessage {
  color: $color-white;
}
.text-purple {
  color: $color-purple !important;
}
.text-white {
  color: $color-white !important;
}

.bg-purple {
  background-color: $color-purple !important;
}
.bg-white {
  background-color: $color-white !important;
}
.bg-black-50 {
  background-color: $color-black-50 !important;
}

.font-newtown {
  font-family: "Newtown";
}

// ========== Fonts ===========

@font-face {
  font-family: "Newtown";
  src: url("./fonts/newtown/Newtown.ttf.woff") format("woff"),
    url("./fonts/newtown/Newtown.ttf.svg#Newtown") format("svg"),
    url("./fonts/newtown/Newtown.ttf.eot"),
    url("./fonts/newtown/Newtown.ttf.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}
// Header
#user_dropdown,
.custom_btn {
  background-color: $color-purple !important;
  border: $color-purple !important;
}
// Course detail page

/* Course details page */

.course_details_bg {
  height: 10em;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .course_title {
    padding-left: 1em;
    padding-top: 7em;
    h2 {
      color: $color-white !important;
      text-shadow: 1px 1px $color-black-50;
    }
  }
}

a {
  color: $color-purple !important;
}
// Lobby
.lobbyHead {
  // background: rgb(33, 7, 34);
  // background: -moz-linear-gradient(
  //   90deg,
  //   rgba(33, 7, 34, 1) 0%,
  //   rgba(74, 11, 77, 1) 17%,
  //   rgba(105, 53, 107, 1) 46%,
  //   rgba(255, 255, 255, 1) 100%
  // );
  // background: -webkit-linear-gradient(
  //   90deg,
  //   rgba(33, 7, 34, 1) 0%,
  //   rgba(74, 11, 77, 1) 17%,
  //   rgba(105, 53, 107, 1) 46%,
  //   rgba(255, 255, 255, 1) 100%
  // );
  // background: linear-gradient(
  //   90deg,
  //   rgba(33, 7, 34, 1) 0%,
  //   rgba(74, 11, 77, 1) 17%,
  //   rgba(105, 53, 107, 1) 46%,
  //   rgba(255, 255, 255, 1) 100%
  // );
  // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#210722",endColorstr="#ffffff",GradientType=1);
  // h1 {
  //   color: $color-white;
  // }
  // background: rgb(34, 103, 125);
  // background: -moz-linear-gradient(
  //   90deg,
  //   rgba(34, 103, 125, 1) 0%,
  //   rgba(20, 143, 182, 1) 21%,
  //   rgba(25, 186, 238, 1) 46%,
  //   rgba(255, 255, 255, 1) 100%
  // );
  // background: -webkit-linear-gradient(
  //     90deg,
  //     rgba(34, 103, 125, 1) 0%,
  //     rgba(20, 143, 182, 1) 21%,
  //     rgba(25, 186, 238, 1) 46%,
  //     rgba(255, 255, 255, 1) 100%
  //   ),
  //   url(../img/home/reminisciBackground.png);
  background-image: url(../img/home/reminisciBackground2.png),
    linear-gradient(
      90deg,
      rgba(34, 103, 125, 1) 0%,
      rgba(20, 143, 182, 1) 21%,
      rgba(25, 186, 238, 1) 46%,
      rgba(255, 255, 255, 1) 100%
    );
  background-size: 220px 200px, auto;
  background-repeat: no-repeat;

  // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#22677d",endColorstr="#ffffff",GradientType=1);
}
